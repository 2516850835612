// Home.tsx
import React from "react";
import "./Home.scss";
import profileImage from "../Assets/home.svg";

const Home: React.FC = () => {
  return (
    <div className="home-body">
      <div className="profile-image-container">
        <img src={profileImage} alt="Your Name" className="profile-image" />
      </div>
      <div className="right-container">
        <div className="box left-top">
          <a href="/resume">Work Resume</a>
        </div>
        <div className="box right-top">
          <a href="/about">About Me</a>
        </div>
        <div className="box left-bottom">
          <a href="/art">Art</a>
        </div>
        <div className="box right-bottom">
          <a href="/music">Music</a>
        </div>
      </div>
    </div>
  );
};

export default Home;
