import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./MobileNav.scss";
import logo from "../Assets/logo.svg";

const MobileNav: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    console.log("isOpen:", isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="mobile-navbar">
      <div className="mobile-navbar-header">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Your Name" className="logo" />
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`bar ${isOpen ? "open" : ""}`}></div>
          <div className={`bar ${isOpen ? "open" : ""}`}></div>
          <div className={`bar ${isOpen ? "open" : ""}`}></div>
        </div>
      </div>

      {isOpen && (
        <div className="menu-dropdown">
          <Link to="/resume" className="navbar-link" onClick={closeMenu}>
            Resume
          </Link>
          <Link to="/about" className="navbar-link" onClick={closeMenu}>
            About
          </Link>
          <Link to="/art" className="navbar-link" onClick={closeMenu}>
            Art
          </Link>
          <Link to="/music" className="navbar-link" onClick={closeMenu}>
            Music
          </Link>
        </div>
      )}
    </nav>
  );
};

export default MobileNav;
